@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

:root {
  --color-bg: #e7dfd8;
  --border-general: 2px solid black;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Playfair Display", serif;
  background: var(--color-bg);
  line-height: 1.7;
}

::placeholder {
  font-family: "Playfair", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

a:hover {
  color: black;
}

@media screen and (max-width: 1050px) {
  h1 {
    font-size: 1.5rem;
  }
}

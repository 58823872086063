.contact {
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: var(--border-general);
  resize: none;
  color: black;
}
.form-submit {
  padding: 9px 25px;
  background-color: black;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: white;
}

@media screen and (max-width: 350px) {
  .form-submit {
    font-size: 10px;
  }
}

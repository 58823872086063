header {
  padding-top: 2rem;
  border: var(--border-general);
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.bio {
  display: flex;
  flex-direction: column;
}

.me {
  display: flex;
  height: 30rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
}

.header_container p {
  padding-right: 10rem;
  padding-left: 2rem;
  padding-bottom: 5rem;
  font-size: 1.5rem;
}

.header_container h1 {
  padding-left: 21rem;
  font-size: 3rem;
}

.me img {
  border-radius: 1rem;
}

@media screen and (max-width: 1045px) {
  .bio p {
    font-size: 1rem;
    text-align: left;
  }
  .bio h1 {
    font-size: 1.7rem;
    padding-left: 2rem;
  }
  .me {
    height: 18rem;
  }
}
@media screen and (max-width: 850px) {
  .me {
    height: 15rem;
  }
  .bio p {
    font-size: 13px;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .bio p {
    font-size: 13px;
    text-align: justify;
    padding-right: 2rem;
  }
  .bio h1 {
    font-size: 26px;
  }
  .me {
    height: 14rem;
  }
}

@media screen and (max-width: 650px) {
  .me img {
    height: 12rem;
  }
  .bio p {
    font-size: 9px;
  }
  .bio h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  header {
    padding-top: 0rem;
  }
  .me img {
    height: 9rem;
    transform: translateY(2rem);
  }
  .me {
    padding-bottom: 0rem;
  }
  .bio p {
    font-size: 8px;
    padding-bottom: 0rem;
  }
  .bio h1 {
    font-size: 15px;
  }
}
@media screen and (max-width: 350px) {
  .header_container {
    display: grid;
    grid-template-columns: auto;
  }
  .bio h1 {
    text-align: center;
    margin-right: 2rem;
  }
  .bio p {
    text-align: justify;
  }
  .me {
    display: grid;
    justify-content: center;
    padding-right: 0rem;
  }
  .me img {
    height: 10rem;
  }
}

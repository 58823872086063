.skills h1 {
  text-align: center;
}

.skills-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-content: left;
  text-align: justify;
}

.skills-container h2 {
  padding-left: 1rem;
}

.skills-practical {
  border: var(--border-general);
}

.skills-technical {
  border: var(--border-general);
}

@media screen and (max-width: 1050px) {
  .skills-container h2 {
    font-size: 1.25rem;
  }
  .skills-container li {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 850px) {
  .skills-practical li {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .skills-container li {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 350px) {
  .skills h1 {
    font-size: 20px;
  }
  .skills-container h2 {
    font-size: 14px;
  }
  .skills-container li {
    font-size: 10px;
  }
  .skills-container {
    display: grid;
    grid-template-columns: auto;
  }
}

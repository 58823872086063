.about h1 {
  text-align: center;
}
.about-container {
  border: var(--border-general);
  display: flex;
  gap: 2rem;
  align-items: center;
}

.about-education {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 8rem;
}

.about-education h5 {
  text-align: center;
}

.about-accolades {
  display: flex;
  text-align: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 12rem;
}

.bero {
  display: inline-block;
  height: 30rem;
  border-radius: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 10rem;
}

@media screen and (max-width: 1050px) {
  .bero {
    display: none;
  }
  .about-container {
    border: none;
  }
  .about-education {
    padding-left: 0rem;
    border: var(--border-general);
    padding-bottom: 1.9rem;
  }
  .about-accolades {
    border: var(--border-general);
    padding-bottom: 1.078925rem;
  }
  .about-container h2 {
    font-size: 1.3rem;
  }
  .about-container h3 {
    font-size: 1rem;
  }
  .about-container p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 850px) {
  .about-education h3 {
    font-size: 0.9rem;
  }
  .about-education {
    padding-bottom: 2.9rem;
  }
}

@media screen and (max-width: 800px) {
  .about-accolades {
    padding-bottom: 1.4rem;
  }
}

@media screen and (max-width: 650px) {
  .about-education h5 {
    margin-left: 0.5rem;
  }
  .about-accolades {
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .about {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
  }
  .about-container {
    display: grid;
  }
  .about-container h2 {
    font-size: 16px;
  }
  .about-education {
    padding-bottom: 0rem;
  }
  .about-education h3 {
    font-size: 12px;
  }
  .about-education h5 {
    font-size: 10px;
  }
  .about-accolades {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
  }
  .about-accolades h3 {
    font-size: 12px;
  }
  .about-accolades p {
    font-size: 10px;
  }
}

@media screen and (max-width: 350px) {
  .about h1 {
    font-size: 20px;
  }
  .about h5 {
    text-align: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .about-container h2 {
    font-size: 14px;
  }
  .about-container h3 {
    font-size: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .about-education h5 {
    font-size: 8px;
  }
  .about-accolades p {
    font-size: 8px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

li,
a,
button {
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 16px;
}
nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav.sticky {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
  position: sticky;
  top: 0;
  background-color: var(--color-bg);
}

.nav_links {
  list-style: none;
}

.nav_links li {
  display: inline-block;
  padding: 0px 20px;
}

.nav_links li a {
  transition: all 0.3s ease 0s;
}

.nav_links li a:hover {
  color: black;
}

.icon {
  margin-right: auto;
}

.connect-button {
  padding: 9px 25px;
  background-color: black;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: white;
}

button:hover {
  background-color: white;
  color: black;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: none;
  border: 1px solid black;
  position: absolute;
  background-color: var(--color-bg);
  z-index: 1;
}

.dropdown-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  color: white;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
@media screen and (max-width: 850px) {
  li,
  a,
  button {
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
  li,
  a,
  button {
    font-size: 11px;
  }
  .dropdown {
    padding-top: 2px;
  }
}

@media screen and (max-width: 650px) {
  li,
  a,
  button {
    font-size: 11px;
  }
  .nav_links li {
    padding: 0px 5px;
  }
  .connect-button {
    padding: 4px 10px;
  }
}

@media screen and (max-width: 500px) {
  li,
  a,
  button {
    font-size: 9.7px;
  }
  nav {
    z-index: 1;
  }
}

@media screen and (max-width: 350px) {
  li,
  a,
  button {
    font-size: 5.9px;
  }
  .nav_links {
    padding: 0px 0px;
  }
  nav {
    justify-content: left;
  }
  .icon {
    margin-right: 0;
  }
}

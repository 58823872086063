.experience-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
}

.experience h1 {
  text-align: center;
}

.experience-details-ap {
  border: var(--border-general);
  padding-bottom: 1.7rem;
}
.experience-details-ap2 {
  border: var(--border-general);
}
.experience-details-abb {
  border: var(--border-general);
  padding-bottom: 1.7rem;
}
.experience-details-ds {
  border: var(--border-general);
}

.experience-container h2,
h5,
p {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: justify;
}

@media screen and (max-width: 1050px) {
  .experience-details-abb {
    padding-bottom: 3.3rem;
  }
  .experience-container h2 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 850px) {
  .experience-details-ap {
    padding-bottom: 3.4rem;
  }
  .experience-details-abb {
    padding-bottom: 5.1rem;
  }
}

@media screen and (max-width: 800px) {
  .experience-details-ap {
    padding-bottom: 3.4rem;
  }
  .experience-details-abb {
    padding-bottom: 5.1rem;
  }
}

@media screen and (max-width: 650px) {
  .experience-details-ap {
    padding-bottom: 3rem;
  }
  .experience-details-abb {
    padding-bottom: 4.5rem;
  }
  .experience-container {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .experience-container h2 {
    font-size: 16px;
  }

  .experience-container h5 {
    font-size: 10px;
  }
  .experience-container p {
    font-size: 12px;
  }
  .experience-container {
    grid-template-columns: auto;
  }
  .experience-details-ap {
    padding-bottom: 0rem;
  }
  .experience-details-abb {
    padding-bottom: 0rem;
  }
}

@media screen and (max-width: 350px) {
  .experience h1 {
    font-size: 20px;
  }
  .experience-container h2 {
    font-size: 14px;
  }
  .experience-container h5 {
    font-size: 8px;
  }
  .experience-container p {
    font-size: 10px;
  }
}
